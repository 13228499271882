var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px","persistent":"","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),(_vm.editedItem.isSuperuser)?_c('v-alert',{attrs:{"tile":"","type":"warning"}},[_vm._v(_vm._s(_vm.$t("warningSuperuser")))]):_vm._e(),_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.formTitle)+" "),_c('v-spacer'),(_vm.hasPermission('change_customuser') && _vm.object)?_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","rounded":"","small":"","dark":""},on:{"click":_vm.onChangePassword}},[_vm._v(_vm._s(`${_vm.$t("label.change")} ${_vm.$t("label.password")}`))]):_vm._e()],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-form',{ref:"form"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('label',[_c('h4',[_vm._v(" "+_vm._s(_vm.$tc("label.type", 1))+" ")])]),_c('v-radio-group',{staticClass:"mt-1",attrs:{"rules":[_vm.rules.required],"row":""},model:{value:(_vm.editedItem.userType),callback:function ($$v) {_vm.$set(_vm.editedItem, "userType", $$v)},expression:"editedItem.userType"}},[_c('v-radio',{attrs:{"label":`${_vm.$tc(
										'label.tenant',
										1
									)} ${_vm.$tc('label.user', 1)}`,"value":"tenantUser"}}),_c('v-radio',{attrs:{"label":`${_vm.$tc(
										'label.partner',
										1
									)} ${_vm.$tc('label.user', 1)}`,"value":"partnerUser"}})],1)],1),(
								_vm.me.isSuperuser &&
								_vm.editedItem.userType === 'tenantUser'
							)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-switch',{staticClass:"my-0 py-0",attrs:{"label":'Superuser',"hide-details":""},model:{value:(_vm.editedItem.isSuperuser),callback:function ($$v) {_vm.$set(_vm.editedItem, "isSuperuser", $$v)},expression:"editedItem.isSuperuser"}})],1):_vm._e(),(
								(_vm.me.isSuperuser || _vm.me.isPartnerUser) &&
								_vm.editedItem.userType === 'tenantUser'
							)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.tenants.edges,"item-text":"node.tenantName","item-value":"node.id","loading":_vm.$apollo.queries.tenants.loading,"rules":[_vm.rules.required],"label":_vm.$tc('label.tenant', 1),"outlined":"","clearable":""},model:{value:(_vm.editedItem.tenantNodeId),callback:function ($$v) {_vm.$set(_vm.editedItem, "tenantNodeId", $$v)},expression:"editedItem.tenantNodeId"}})],1):_vm._e(),(
								_vm.me.isSuperuser &&
								_vm.editedItem.userType === 'partnerUser'
							)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.allPartners.edges,"item-text":"node.name","item-value":"node.id","loading":_vm.$apollo.queries.allPartners.loading,"rules":[_vm.rules.required],"label":_vm.$tc('label.partner', 1),"outlined":"","clearable":""},model:{value:(_vm.editedItem.partnerNodeId),callback:function ($$v) {_vm.$set(_vm.editedItem, "partnerNodeId", $$v)},expression:"editedItem.partnerNodeId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":100,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(100),
								],"label":_vm.$t('label.firstName'),"outlined":""},model:{value:(_vm.editedItem.firstName),callback:function ($$v) {_vm.$set(_vm.editedItem, "firstName", $$v)},expression:"editedItem.firstName"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":100,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(100),
								],"label":_vm.$t('label.lastName'),"outlined":""},model:{value:(_vm.editedItem.lastName),callback:function ($$v) {_vm.$set(_vm.editedItem, "lastName", $$v)},expression:"editedItem.lastName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":100,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(100),
								],"label":_vm.$t('label.email'),"type":"email","outlined":""},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.identityProviders,"item-text":"name","item-value":"id","rules":[_vm.rules.required],"label":_vm.$t('label.identityProvider'),"outlined":"","clearable":""},model:{value:(_vm.editedItem.identityProvider),callback:function ($$v) {_vm.$set(_vm.editedItem, "identityProvider", $$v)},expression:"editedItem.identityProvider"}})],1),(!_vm.editedItem.isSuperuser)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.groups.edges,"item-text":"node.name","item-value":"node.id","loading":_vm.$apollo.queries.groups.loading,"rules":[_vm.rules.required],"label":_vm.$tc('label.group', 2),"outlined":"","clearable":"","multiple":""},model:{value:(_vm.editedItem.selectedGroups),callback:function ($$v) {_vm.$set(_vm.editedItem, "selectedGroups", $$v)},expression:"editedItem.selectedGroups"}})],1):_vm._e(),(!_vm.object)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"append-icon":_vm.showpassword
										? 'visibility'
										: 'visibility_off',"counter":50,"label":_vm.$tc('label.password', 1),"rules":[_vm.rules.required, _vm.rules.strength],"type":_vm.showpassword ? 'text' : 'password',"outlined":""},on:{"click:append":function($event){_vm.showpassword = !_vm.showpassword}},model:{value:(_vm.editedItem.password),callback:function ($$v) {_vm.$set(_vm.editedItem, "password", $$v)},expression:"editedItem.password"}}),_c('v-progress-linear',{class:['mb-7'],attrs:{"color":_vm.strengthScore(_vm.editedItem.password).color,"value":_vm.strengthScore(_vm.editedItem.password).value}})],1):_vm._e(),(
								_vm.editedItem.identityProvider == 'TAPTARGET'
							)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{staticClass:"my-0 py-0",attrs:{"label":_vm.$t('label.require2fa'),"hide-details":""},model:{value:(_vm.editedItem.require2fa),callback:function ($$v) {_vm.$set(_vm.editedItem, "require2fa", $$v)},expression:"editedItem.require2fa"}})],1):_vm._e(),(
								_vm.object &&
								_vm.editedItem.identityProvider == 'TAPTARGET'
							)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"align-center"},[(
										_vm.editedItem.enabled2fa &&
										!_vm.editedItem.clear2fa
									)?_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"6"}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("verified_user")]),_vm._v(" "+_vm._s(_vm.$t("2faEnabledText"))+" ")],1):(
										_vm.editedItem.enabled2fa &&
										_vm.editedItem.clear2fa
									)?_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"6"}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("highlight_off")]),_vm._v(" "+_vm._s(_vm.$t("2faResetText"))+" ")],1):_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"6"}},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("highlight_off")]),_vm._v(" "+_vm._s(_vm.$t("2faNotSetText"))+" ")],1),(_vm.editedItem.enabled2fa)?_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"color":"error","label":_vm.$t('2faReset')},model:{value:(_vm.editedItem.clear2fa),callback:function ($$v) {_vm.$set(_vm.editedItem, "clear2fa", $$v)},expression:"editedItem.clear2fa"}})],1):_vm._e()],1)],1):_vm._e()],1)],1)],1),_c('v-card-actions',{staticClass:"pb-5 pr-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isSaving,"text":"","rounded":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("label.cancel")))]),_c('v-btn',{attrs:{"outlined":"","rounded":"","loading":_vm.isSaving,"color":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("label.save")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
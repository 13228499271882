<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col :class="['my-0', 'py-0']" cols="12">
          <v-card flat>
            <v-card-title
              :class="['font-weight-bold', 'text-h4']"
              style="word-break: break-word"
            >
              {{ $t("title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("subtitle") }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col :class="['my-0', 'py-0']" cols="12">
          <v-card flat>
            <v-card-text>
              <v-expansion-panels v-model="panel">
                <TapsPanel />
                <OrchestPanel v-if="me.isSuperuser" />
                <AfasPanel v-if="me.isSuperuser" />
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import helper from "@/utils/helper.js";
import AfasPanel from "@/components/integrations/controlPanel/afas/AfasPanel.vue";
import OrchestPanel from "@/components/integrations/controlPanel/orchest/OrchestPanel.vue";
import TapsPanel from "@/components/integrations/controlPanel/taps/TapsPanel.vue";

export default {
  name: "control-panel-list",
  components: {
    AfasPanel,
    OrchestPanel,
    TapsPanel,
  },

  apollo: {},

  data: function () {
    return {
      error: null,
      panel: 0,
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },

  watch: {},

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;
  },

  mounted() {},

  methods: {},
};
</script>

<i18n>
{
  "en": {
    "title": "Control panel",
    "subtitle": "Manage all your integrations and tools in one place. The control panel provides easy access to setup, automation, and administrative features for partners and superusers."
  },
  "nl": {
    "title": "Configuratiescherm",
    "subtitle": "Beheer al jouw integraties en tools op één plek. Het configuratiescherm biedt eenvoudig toegang tot installatie, automatisering en administratieve functies voor partners en superusers."
  },
  "de": {
    "title": "Bedienfeld",
    "subtitle": "Verwalten Sie alle Ihre Integrationen und Tools an einem Ort. Das Bedienfeld bietet einfachen Zugriff auf Einrichtung, Automatisierung und Verwaltungsfunktionen für Partner und Superuser."
  }
}
</i18n>

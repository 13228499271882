<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col :class="['my-0', 'py-0']" cols="12">
          <v-card flat>
            <v-card-title
              :class="['font-weight-bold', 'text-h4']"
              style="word-break: break-word"
            >
              {{ $t('title') }}
            </v-card-title>
            <v-card-text>
              {{ $t('subtitle') }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="2" sm="6">
          <v-text-field
            :label="$t('label.search')"
            @click:append-outer="refresh()"
            @click:clear="clearSearch()"
            @keydown.enter="refresh()"
            clearable
            dense
            hide-details
            outlined
            required
            v-model="search"
          ></v-text-field>
        </v-col>

        <v-spacer />

        <v-col cols="12" md="2" align="right">
          <v-btn
            @click="resetFilters()"
            color="primary"
            dark
            rounded
            small
            text
          >
            {{ $t("label.clearFilters") }}
          </v-btn>
        </v-col>

        <v-col cols="12" md="12">
          <v-data-table
            :footer-props="{
              'disable-pagination': $apollo.queries.reports.loading,
              'items-per-page-options': [10, 25, 50, 75, 100],
            }"
            :headers="headers"
            :items="reports.edges"
            :loading="$apollo.queries.reports.loading"
            :options.sync="dataTableOptions"
            :server-items-length="reports.totalCount"
            item-key="node.id"
          >
            <template v-slot:[`item.node.reportType`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    :color="item.node.isStandard ? 'warning' : 'primary'"
                    >{{
                      item.node.reportType == "POWERBIREPORT"
                        ? "dashboard"
                        : "layers"
                    }}</v-icon
                  >
                </template>
                <span>
                  {{
                    item.node.reportType == "POWERBIREPORT" &&
                    item.node.isStandard
                      ? $tc("report.standardDashboard", 1)
                      : item.node.reportType == "POWERBIREPORT"
                      ? $tc("report.customDashboard", 1)
                      : $tc("report.paginatedReport")
                  }}
                </span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.node.reportName`]="{ item }">
              <span>{{
                item.node.isStandard
                  ? item.node.tenant.tenantName + " - " + item.node.reportName
                  : item.node.reportName
              }}</span>
            </template>
            <template v-slot:[`item.node.lastRefreshStatus`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    :color="
                      item.node.lastRefreshStatus == 'Completed'
                        ? 'success'
                        : item.node.lastRefreshStatus == 'Failed'
                        ? 'error'
                        : item.node.lastRefreshStatus == 'Disabled'
                        ? 'error'
                        : item.node.lastRefreshStatus == 'Unknown'
                        ? 'warning'
                        : ''
                    "
                    class="mr-2"
                    v-on="on"
                    >{{
                      item.node.lastRefreshStatus == "Completed"
                        ? "check_circle"
                        : item.node.lastRefreshStatus == "Failed"
                        ? "cancel"
                        : item.node.lastRefreshStatus == "Disabled"
                        ? "cancel"
                        : item.node.lastRefreshStatus == "Unknown"
                        ? "error"
                        : ""
                    }}</v-icon
                  >
                </template>
                <span>
                  {{
                    item.node.lastRefreshStatus == "Unknown"
                      ? "Refreshing"
                      : item.node.lastRefreshStatus
                  }}
                </span>
              </v-tooltip>
              <span v-if="item.node.lastRefreshEndTime">{{
                item.node.lastRefreshEndTime | moment("YYYY-MM-DD HH:mm")
              }}</span>
            </template>
            <template v-slot:[`item.node.refreshScheduleIsEnabled`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    :color="
                      item.node.isDirectQuery
                        ? 'info'
                        : item.node.refreshScheduleIsEnabled
                        ? 'success'
                        : 'error'
                    "
                    class="mr-2"
                    v-on="on"
                  >
                    {{
                      item.node.isDirectQuery
                        ? "swap_horizontal_circle"
                        : item.node.refreshScheduleIsEnabled
                        ? "check_circle"
                        : "cancel"
                    }}
                  </v-icon>
                </template>
                <span>
                  {{
                    item.node.isDirectQuery
                      ? "Direct query"
                      : item.node.refreshScheduleIsEnabled
                      ? "Enabled"
                      : "Disabled"
                  }}
                </span>
              </v-tooltip>
              <span v-if="item.node.isDirectQuery"></span>
              <span v-else-if="item.node?.refreshScheduleTimes?.length <= 54">{{
                item.node.refreshScheduleTimes
              }}</span>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    item.node?.refreshScheduleTimes?.substring(0, 54) + "..."
                  }}</span>
                </template>
                <span>
                  {{ item.node.refreshScheduleTimes }}
                </span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.node.updatedAt`]="{ item }">
              {{ item.node.updatedAt | moment("YYYY-MM-DD HH:mm:SS") }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                @click="openPowerBIServiceReport(item.node)"
                color="primary"
                icon
                v-if="me.isSuperuser"
              >
                <v-icon>bar_chart</v-icon>
              </v-btn>
              <v-btn
                @click="openPowerBIServiceDataset(item.node)"
                color="primary"
                icon
                v-if="me.isSuperuser"
              >
                <v-icon>account_tree</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
  name: "error-list",
  components: {},

  apollo: {
    reports: {
      query: gql`
        query reports(
          $first: Int
          $last: Int
          $before: String
          $after: String
          $search: String
          $orderBy: [String]
          $ownReports: Boolean!
          $errors: Boolean
        ) {
          reports(
            first: $first
            last: $last
            before: $before
            after: $after
            search: $search
            orderBy: $orderBy
            ownReports: $ownReports
            errors: $errors
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                bindedDatasetId
                bindedGroupId
                connection {
                  remainingTrialPeriod
                }
                datasetId
                groupId
                isBinded
                isDirectQuery
                isStandard
                lastRefreshEndTime
                lastRefreshStatus
                refreshScheduleIsEnabled
                refreshScheduleTimes
                reportId
                reportName
                reportType
                tenant {
                  id
                  tenantName
                }
                updatedAt
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.reports,
      skip: true,
      pollInterval: 600000,
    },
  },

  data: function () {
    return {
      dataTableOptions: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 100,
        multiSort: true,
        mustSort: false,
        page: 1,
        sortBy: ["node.reportName"],
        sortDesc: [false],
      },
      tenants: {},
      expanded: [],
      headers: [
        {
          text: this.$tc("label.type", 1),
          value: "node.reportType",
          sortable: false,
        },
        {
          text: this.$tc("label.tenant", 1),
          value: "node.tenant.tenantName",
          sortable: !this.groupBy,
        },
        {
          text: this.$tc("label.report", 1),
          value: "node.reportName",
          sortable: !this.groupBy,
        },
        {
          text: this.$tc("report.lastRefresh", 1),
          value: "node.lastRefreshStatus",
          sortable: false,
        },
        {
          text: this.$tc("report.refreshSchedule", 1),
          value: "node.refreshScheduleIsEnabled",
          sortable: false,
        },
        {
          text: this.$t("label.updated"),
          value: "node.updatedAt",
          sortable: true,
        },
        {
          text: this.$t("label.action"),
          align: "center",
          value: "action",
          sortable: false,
        },
      ],
      reports: {},
      error: null,
      search: null,
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },

  watch: {
    dataTableOptions() {
      this.refresh();
    },
  },

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;
  },

  mounted() {},

  methods: {
    clearSearch() {
      this.search = null;
      this.refresh();
    },

    openPowerBIServiceReport(item) {
      var groupId = item.groupId;
      var reportId = item.reportId;
      if (item.reportType === "PAGINATEDREPORT") {
        var reportType = "rdlreports";
      } else {
        var reportType = "reports";
      }
      var url = `https://app.powerbi.com/groups/${groupId}/${reportType}/${reportId}`;

      window.open(url, "_blank");
    },

    openPowerBIServiceDataset(item) {
      var bindedDatasetId = item.bindedDatasetId;
      var bindedGroupId = item.bindedGroupId;
      var datasetId = item.datasetId;
      var groupId = item.groupId;
      var reportId = item.reportId;
      if (item.reportType === "PAGINATEDREPORT") {
        var uri = `groups/${groupId}/settings/reports/${reportId}`;
      } else if (item.isBinded) {
        var uri = `groups/${bindedGroupId}/datasets/${bindedDatasetId}/details`;
      } else {
        var uri = `groups/${groupId}/datasets/${datasetId}/details`;
      }
      var url = `https://app.powerbi.com/${uri}`;

      window.open(url, "_blank");
    },

    refresh() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataTableOptions;

      var _first = itemsPerPage;
      var _last = null;
      var _before = null;
      var _after = null;

      if (page < this.page) {
        _first = null;
        _last = itemsPerPage;
        _before = this.reports.pageInfo.startCursor;
        _after = null;
      }

      if (page > this.page) {
        _first = itemsPerPage;
        _last = null;
        _before = null;
        _after = this.reports.pageInfo.endCursor;
      }

      var orderByList = this.orderByList(sortBy, sortDesc);

      this.$apollo.queries.reports.setVariables({
        first: _first,
        last: _last,
        before: _before,
        after: _after,
        orderBy: orderByList,
        search: this.search,
        ownReports: false,
        errors: true,
      });

      this.$apollo.queries.reports.skip = false;
      this.$apollo.queries.reports.refresh();

      this.page = page;
    },

    resetFilters() {
      this.clearSearch();
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Power BI refresh errors",
    "subtitle": "This page shows all the refresh errors or disabled refresh schedules. Every 15 minutes the refreshes are synced from Power BI to the portal automatically. Direct query reports are excluded, since they do not have a scheduled refresh."
  },
  "nl": {
    "title": "Power BI refresh errors",
    "subtitle": "Deze pagina toont alle vernieuwingsfouten of uitgeschakelde vernieuwingsplanningen. Elke 15 minuten worden de vernieuwingen automatisch gesynchroniseerd van Power BI naar het portaal. Direct query rapporten zijn uitgesloten, omdat ze geen geplande vernieuwing hebben."
  },
  "de": {
    "title": "Power BI refresh errors",
    "subtitle": "Diese Seite zeigt alle Aktualisierungsfehler oder deaktivierten Aktualisierungspläne an. Alle 15 Minuten werden die Aktualisierungen automatisch von Power BI auf das Portal synchronisiert. Direktabfrageberichte sind ausgeschlossen, da sie keine geplante Aktualisierung haben."
  }
}
</i18n>

export const resourceGroup = {
  $schema:
    "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
  contentVersion: "1.0.0.0",
  outputs: {},
  parameters: {
    resource_group: { type: "string" },
    resource_group_location: { type: "string" },
    resource_tags: { type: "object" },
  },
  resources: [
    {
      apiVersion: "2021-04-01",
      location: "[parameters('resource_group_location')]",
      name: "[parameters('resource_group')]",
      properties: {},
      tags: "[parameters('resource_tags')]",
      type: "Microsoft.Resources/resourceGroups",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "lockDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2016-09-01",
              name: "RGLock",
              properties: {
                level: "CanNotDelete",
                notes:
                  "Resource group and its resources should not be deleted.",
              },
              type: "Microsoft.Authorization/locks",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
  ],
  variables: {},
};

export const keyVault = {
  $schema:
    "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
  contentVersion: "1.0.0.0",
  outputs: {},
  parameters: {
    key_vault: { type: "string" },
    resource_group: { type: "string" },
    resource_group_location: { type: "string" },
    resource_tags: { type: "object" },
  },
  resources: [
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "vaultDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2022-07-01",
              location: "[parameters('resource_group_location')]",
              name: "[parameters('key_vault')]",
              properties: {
                accessPolicies: [],
                enableRbacAuthorization: true,
                enableSoftDelete: true,
                enabledForDeployment: false,
                enabledForDiskEncryption: false,
                enabledForTemplateDeployment: false,
                provisioningState: "Succeeded",
                publicNetworkAccess: "Enabled",
                sku: { family: "A", name: "Standard" },
                softDeleteRetentionInDays: 90,
                tenantId: "ce278e4a-fec6-419a-bde1-407f367aed79",
              },
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.KeyVault/vaults",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
  ],
  variables: {},
};

export const dataFactory = {
  $schema:
    "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
  contentVersion: "1.0.0.0",
  outputs: {},
  parameters: {
    action_group: { type: "string" },
    data_factory: { type: "string" },
    resource_group: { type: "string" },
    resource_group_location: { type: "string" },
    resource_tags: { type: "object" },
  },
  resources: [
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "dataFactoryDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2018-06-01",
              identity: { type: "SystemAssigned" },
              location: "[parameters('resource_group_location')]",
              name: "[parameters('data_factory')]",
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.DataFactory/factories",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: ["dataFactoryDeployment"],
      name: "alertDeploymentDataFactory",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              type: "microsoft.insights/metricAlerts",
              apiVersion: "2018-03-01",
              name: "[concat(parameters('data_factory'), ' failed')]",
              location: "global",
              properties: {
                severity: 1,
                enabled: true,
                scopes: [
                  "[concat('/subscriptions/aa86c9a9-d98a-474c-9634-5eb69681a197/resourceGroups/', parameters('resource_group'), '/providers/Microsoft.DataFactory/factories/', parameters('data_factory'))]",
                ],
                evaluationFrequency: "PT1M",
                windowSize: "PT5M",
                criteria: {
                  allOf: [
                    {
                      threshold: 0,
                      name: "Metric1",
                      metricNamespace: "Microsoft.DataFactory/factories",
                      metricName: "PipelineFailedRuns",
                      operator: "GreaterThan",
                      timeAggregation: "Total",
                      skipMetricValidation: false,
                      criterionType: "StaticThresholdCriterion",
                    },
                  ],
                  "odata.type":
                    "Microsoft.Azure.Monitor.SingleResourceMultipleMetricCriteria",
                },
                autoMitigate: false,
                targetResourceType: "Microsoft.DataFactory/factories",
                targetResourceRegion: "westeurope",
                actions: [
                  {
                    actionGroupId: "[parameters('action_group')]",
                    webHookProperties: {},
                  },
                ],
              },
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
  ],
  variables: {},
};

export const database = {
  $schema:
    "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
  contentVersion: "1.0.0.0",
  outputs: {},
  parameters: {
    key_vault: { type: "string" },
    resource_group: { type: "string" },
    resource_group_location: { type: "string" },
    resource_tags: { type: "object" },
    sql_server: { type: "string" },
    sql_database: { type: "string" },
    sql_user: { type: "string" },
    sql_password: { type: "SecureString" },
    sql_server_location: { type: "string" },
  },
  resources: [
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "vaultDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2022-07-01",
              location: "[parameters('resource_group_location')]",
              name: "[parameters('key_vault')]",
              properties: {
                accessPolicies: [],
                enableRbacAuthorization: true,
                enableSoftDelete: true,
                enabledForDeployment: false,
                enabledForDiskEncryption: false,
                enabledForTemplateDeployment: false,
                provisioningState: "Succeeded",
                publicNetworkAccess: "Enabled",
                sku: { family: "A", name: "Standard" },
                softDeleteRetentionInDays: 90,
                tenantId: "ce278e4a-fec6-419a-bde1-407f367aed79",
              },
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.KeyVault/vaults",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "serverDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2022-02-01-preview",
              kind: "v12.0",
              location: "[parameters('sql_server_location')]",
              name: "[parameters('sql_server')]",
              properties: {
                administratorLogin: "[parameters('sql_user')]",
                administratorLoginPassword: "[parameters('sql_password')]",
                administrators: {
                  administratorType: "ActiveDirectory",
                  azureADOnlyAuthentication: false,
                  login: "admin@taptarget.io",
                  principalType: "Group",
                  sid: "1ba19d7e-9603-487e-adf2-eca66d138695",
                  tenantId: "ce278e4a-fec6-419a-bde1-407f367aed79",
                },
                publicNetworkAccess: "Enabled",
                restrictOutboundNetworkAccess: "Disabled",
                version: "12.0",
              },
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.Sql/servers",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: ["serverDeployment"],
      name: "serverFirewallDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2022-02-01-preview",
              name: "[concat(parameters('sql_server'), '/AllowAllWindowsAzureIps')]",
              properties: {
                endIpAddress: "0.0.0.0",
                startIpAddress: "0.0.0.0",
              },
              type: "Microsoft.Sql/servers/firewallRules",
            },
            {
              apiVersion: "2022-02-01-preview",
              name: "[concat(parameters('sql_server'), '/TapTarget')]",
              properties: {
                endIpAddress: "185.26.57.130",
                startIpAddress: "185.26.57.130",
              },
              type: "Microsoft.Sql/servers/firewallRules",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: ["serverDeployment"],
      name: "databaseDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2022-02-01-preview",
              kind: "v12.0,user",
              location: "[parameters('sql_server_location')]",
              name: "[concat(parameters('sql_server'), '/', parameters('sql_database'))]",
              properties: {
                catalogCollation: "SQL_Latin1_General_CP1_CI_AS",
                collation: "SQL_Latin1_General_CP1_CI_AS",
                isLedgerOn: false,
                maintenanceConfigurationId:
                  "/subscriptions/aa86c9a9-d98a-474c-9634-5eb69681a197/providers/Microsoft.Maintenance/publicMaintenanceConfigurations/SQL_Default",
                maxSizeBytes: 268435456000,
                readScale: "Disabled",
                requestedBackupStorageRedundancy: "Geo",
                zoneRedundant: false,
              },
              sku: {
                capacity: 10,
                name: "Standard",
                tier: "Standard",
              },
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.Sql/servers/databases",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: ["vaultDeployment"],
      name: "secretDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2022-07-01",
              name: "[concat(parameters('key_vault'), '/AzureSQL')]",
              properties: {
                contentType: "text/plain",
                value: "[parameters('sql_password')]",
              },
              tags: {
                Type: "Conection String",
                Url: "[concat(parameters('sql_server'), '.database.windows.net')]",
                Username: "[parameters('sql_user')]",
              },
              type: "Microsoft.KeyVault/vaults/secrets",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
  ],
  variables: {},
};

export const storageAccount = {
  $schema:
    "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
  contentVersion: "1.0.0.0",
  outputs: {},
  parameters: {
    resource_group: { type: "string" },
    resource_group_location: { type: "string" },
    resource_tags: { type: "object" },
    storage_account: { type: "string" },
  },
  resources: [
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "storageDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2022-05-01",
              kind: "StorageV2",
              location: "[parameters('resource_group_location')]",
              name: "[parameters('storage_account')]",
              properties: {
                accessTier: "Hot",
                allowBlobPublicAccess: true,
                allowCrossTenantReplication: true,
                allowSharedKeyAccess: true,
                defaultToOAuthAuthentication: false,
                encryption: {
                  keySource: "Microsoft.Storage",
                  services: {
                    blob: {
                      enabled: true,
                      keyType: "Account",
                    },
                    file: {
                      enabled: true,
                      keyType: "Account",
                    },
                  },
                },
                minimumTlsVersion: "TLS1_2",
                networkAcls: {
                  bypass: "AzureServices",
                  defaultAction: "Allow",
                  ipRules: [],
                  virtualNetworkRules: [],
                },
                supportsHttpsTrafficOnly: true,
              },
              sku: {
                name: "Standard_LRS",
                tier: "Standard",
              },
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.Storage/storageAccounts",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
  ],
  variables: {},
};

export const logicApp = {
  $schema:
    "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
  contentVersion: "1.0.0.0",
  outputs: {},
  parameters: {
    action_group: { type: "string" },
    logic_app: { type: "string" },
    resource_group: { type: "string" },
    resource_group_location: { type: "string" },
    resource_tags: { type: "object" },
  },
  resources: [
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "logicAppDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2016-10-01",
              location: "[parameters('resource_group_location')]",
              name: "[parameters('logic_app')]",
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.Logic/workflows",
              properties: {
                state: "Enabled",
                definition: {
                  contentVersion: "1.0.0.0",
                  parameters: {},
                  actions: {},
                  triggers: {},
                  outputs: {},
                  $schema:
                    "https://schema.management.azure.com/providers/Microsoft.Logic/schemas/2016-06-01/workflowdefinition.json#",
                },
              },
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
	{
		apiVersion: "2021-04-01",
		dependsOn: ["logicAppDeployment"],
		name: "alertDeploymentLogicApp",
		properties: {
		  mode: "Incremental",
		  template: {
			$schema:
			  "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
			contentVersion: "1.0.0.0",
			outputs: {},
			parameters: {},
			resources: [
			  {
				type: "microsoft.insights/metricAlerts",
				apiVersion: "2018-03-01",
				name: "[concat(parameters('logic_app'), ' failed')]",
				location: "global",
				properties: {
				  severity: 1,
				  enabled: true,
				  scopes: [
					"[concat('/subscriptions/aa86c9a9-d98a-474c-9634-5eb69681a197/resourceGroups/', parameters('resource_group'), '/providers/Microsoft.Logic/workflows/', parameters('logic_app'))]",
				  ],
				  evaluationFrequency: "PT1M",
				  windowSize: "PT5M",
				  criteria: {
					allOf: [
					  {
						threshold: 0,
						name: "Metric1",
						metricNamespace: "Microsoft.Logic/workflows",
						metricName: "RunsFailed",
						operator: "GreaterThan",
						timeAggregation: "Total",
						skipMetricValidation: false,
						criterionType: "StaticThresholdCriterion",
					  },
					],
					"odata.type":
					  "Microsoft.Azure.Monitor.SingleResourceMultipleMetricCriteria",
				  },
				  autoMitigate: false,
				  targetResourceType: "Microsoft.Logic/workflows",
				  targetResourceRegion: "westeurope",
				  actions: [
					{
					  actionGroupId: "[parameters('action_group')]",
					  webHookProperties: {},
					},
				  ],
				},
			  },
			],
			variables: {},
		  },
		},
		resourceGroup: "[parameters('resource_group')]",
		type: "Microsoft.Resources/deployments",
	  },
  ],
  variables: {},
};
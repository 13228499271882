var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[(_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{class:['font-weight-bold', 'text-h4'],staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.$t('title'))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('subtitle'))+" ")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('label.search'),"clearable":"","dense":"","hide-details":"","outlined":"","required":""},on:{"click:append-outer":function($event){return _vm.refresh()},"click:clear":function($event){return _vm.clearSearch()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.refresh()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"2","align":"right"}},[_c('v-btn',{attrs:{"color":"primary","dark":"","rounded":"","small":"","text":""},on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v(" "+_vm._s(_vm.$t("label.clearFilters"))+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{attrs:{"footer-props":{
            'disable-pagination': _vm.$apollo.queries.reports.loading,
            'items-per-page-options': [10, 25, 50, 75, 100],
          },"headers":_vm.headers,"items":_vm.reports.edges,"loading":_vm.$apollo.queries.reports.loading,"options":_vm.dataTableOptions,"server-items-length":_vm.reports.totalCount,"item-key":"node.id"},on:{"update:options":function($event){_vm.dataTableOptions=$event}},scopedSlots:_vm._u([{key:`item.node.reportType`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":item.node.isStandard ? 'warning' : 'primary'}},on),[_vm._v(_vm._s(item.node.reportType == "POWERBIREPORT" ? "dashboard" : "layers"))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.node.reportType == "POWERBIREPORT" && item.node.isStandard ? _vm.$tc("report.standardDashboard", 1) : item.node.reportType == "POWERBIREPORT" ? _vm.$tc("report.customDashboard", 1) : _vm.$tc("report.paginatedReport"))+" ")])])]}},{key:`item.node.reportName`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.node.isStandard ? item.node.tenant.tenantName + " - " + item.node.reportName : item.node.reportName))])]}},{key:`item.node.lastRefreshStatus`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":item.node.lastRefreshStatus == 'Completed'
                      ? 'success'
                      : item.node.lastRefreshStatus == 'Failed'
                      ? 'error'
                      : item.node.lastRefreshStatus == 'Disabled'
                      ? 'error'
                      : item.node.lastRefreshStatus == 'Unknown'
                      ? 'warning'
                      : ''}},on),[_vm._v(_vm._s(item.node.lastRefreshStatus == "Completed" ? "check_circle" : item.node.lastRefreshStatus == "Failed" ? "cancel" : item.node.lastRefreshStatus == "Disabled" ? "cancel" : item.node.lastRefreshStatus == "Unknown" ? "error" : ""))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.node.lastRefreshStatus == "Unknown" ? "Refreshing" : item.node.lastRefreshStatus)+" ")])]),(item.node.lastRefreshEndTime)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.node.lastRefreshEndTime,"YYYY-MM-DD HH:mm")))]):_vm._e()]}},{key:`item.node.refreshScheduleIsEnabled`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":item.node.isDirectQuery
                      ? 'info'
                      : item.node.refreshScheduleIsEnabled
                      ? 'success'
                      : 'error'}},on),[_vm._v(" "+_vm._s(item.node.isDirectQuery ? "swap_horizontal_circle" : item.node.refreshScheduleIsEnabled ? "check_circle" : "cancel")+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.node.isDirectQuery ? "Direct query" : item.node.refreshScheduleIsEnabled ? "Enabled" : "Disabled")+" ")])]),(item.node.isDirectQuery)?_c('span'):(item.node?.refreshScheduleTimes?.length <= 54)?_c('span',[_vm._v(_vm._s(item.node.refreshScheduleTimes))]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.node?.refreshScheduleTimes?.substring(0, 54) + "..."))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.node.refreshScheduleTimes)+" ")])])]}},{key:`item.node.updatedAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("moment")(item.node.updatedAt,"YYYY-MM-DD HH:mm:SS"))+" ")]}},{key:`item.action`,fn:function({ item }){return [(_vm.me.isSuperuser)?_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.openPowerBIServiceReport(item.node)}}},[_c('v-icon',[_vm._v("bar_chart")])],1):_vm._e(),(_vm.me.isSuperuser)?_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.openPowerBIServiceDataset(item.node)}}},[_c('v-icon',[_vm._v("account_tree")])],1):_vm._e()]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
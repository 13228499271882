<template>
  <BaseDialog :error="error" :dialog="dialog" @close="close($event)">
    <template v-slot:title> {{ webflowItem?.fieldData?.name }} </template>
    <template v-slot:text>
      <div class="mb-5">
        {{ webflowItem?.fieldData?.["tekst-add-on-portal"] }}
      </div>

      <DynamicForm
        :fields="fields"
        :formData.sync="formData"
        @is-valid-changed="updateFormValid($event)"
        ref="DynamicForm"
      />

      <v-list class="" v-if="webflowItem?.fieldData?.['prijs-per-stuk']">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ webflowItem?.fieldData?.["label-voor-prijs-per-eenheid"] }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-content class="text-right align-self-start">
            <v-list-item-title> €{{ pricePerUnit }},- </v-list-item-title>
            <v-list-item-subtitle class="wrap-text">
              {{ priceFrequency }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list class="mb-5">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ webflowItem?.fieldData?.["tekst-totaal-prijs"] }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-content class="text-right align-self-start">
            <v-list-item-title
              v-if="webflowItem?.fieldData?.['prijs-per-stuk']"
            >
              {{ amount ? "€" + amount + ",-" : "-" }}
            </v-list-item-title>
            <v-list-item-subtitle class="wrap-text">
              {{ priceFrequency }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:actionsLeft>
      <div class="text-body-2 grey--text">
        {{ webflowItem?.fieldData?.["disclaimer"] }}
      </div>
    </template>
    <template v-slot:actionsRight>
      <BaseButton
        :loading="isSaving"
        :text="true"
        @click="close()"
        color="black"
      >
        {{ $t("label.cancel") }}
      </BaseButton>
      <BaseButton :disabled="!formValid" :loading="isSaving" @click="save()">
        {{ $t("label.request") }}
      </BaseButton>
    </template>
  </BaseDialog>
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import DynamicForm from "@/components/base/DynamicForm.vue";
import gql from "graphql-tag";

const initialData = () => ({
  error: null,
  fields: [],
  formData: {},
});

export default {
  name: "add-ons-edit-dialog",
  components: {
    BaseButton,
    BaseDialog,
    DynamicForm,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
    addOn: {
      type: Object,
      required: true,
    },
    webflowItem: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      isSaving: false,
      fields: [],
      formData: {},
      formValid: false,
    };
  },

  computed: {
    pricePerUnit() {
      return Math.round(
        this.webflowItem?.fieldData?.["prijs-per-stuk"] *
          this.webflowItem?.fieldData?.["stuks-per-eenheid"]
      );
    },

    priceFrequency() {
      return this.webflowItem?.fieldData?.["prijs-per-stuk"]
        ? this.webflowItem?.fieldData?.["label-per-maand"]
        : this.webflowItem?.fieldData?.["prijs-op-aanvraag"];
    },

    amount() {
      return this.formData.quantity
        ? Math.round(
            this.formData.quantity *
              this.webflowItem?.fieldData?.["prijs-per-stuk"]
          )
        : null;
    },
  },

  watch: {
    dialog(val) {
      // reset the form to it's original state and reset the validation when dialog closes
      if (!val) {
        this.$emit("update:item", null);
        this.resetData();
        this.$refs.DynamicForm.resetForm();
      } else {
        this.setFormFields();
        this.setFormData(this.item);
      }
    },
  },

  created() {},

  mounted() {},

  methods: {
    close() {
      this.$emit("update:dialog", false);
    },

    resetData() {
      // reset the data to it's initial state
      const data = initialData();
      Object.keys(data).forEach((k) => (this[k] = data[k]));
    },

    setFormFields() {
      // set the field from the config from the DB
      this.fields = JSON.parse(this.addOn.formFields);
    },

    setFormData(item) {
      // edit, not used since item is not used for add ons
      if (item) {
        this.formData = {
          quantity: item.quantity,
        };
      }
      // add
      else {
        this.formData = JSON.parse(this.addOn.formData);
      }
    },

    updateFormValid(isValid) {
      this.formValid = isValid;
    },

    save() {
      if (!this.formValid) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        addOn: this.addOn.id,
        formData: JSON.stringify(this.formData),
        formFields: JSON.stringify(this.fields),
        name: this.webflowItem?.fieldData?.name,
        price: this.webflowItem?.fieldData?.["prijs-per-stuk"],
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createAddOnOrder($input: CreateAddOnOrderInput!) {
              createAddOnOrder(input: $input) {
                clientMutationId
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Add-on successfully requested`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$emit("changed", response);

          this.close();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>

<i18n>
{}
</i18n>
